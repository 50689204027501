import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { composeStyles, twMerge, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import 'swiper/css';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconNext } from '@assets/icon-next';
import { IconPrev } from '@assets/icon-prev';

type CarouselItem = React.ReactElement & PropsWithClassProps<'root'>;

export interface CarouselProps
  extends PropsWithChildren,
    PropsWithClassProps<
      | 'button'
      | 'carousel'
      | 'slidesWrapper'
      | 'slide'
      | 'buttonLeft'
      | 'buttonRight'
      | 'buttonIcon'
      | 'buttonContainer'
    > {
  CustomButtonLeft?: React.ReactNode;
  CustomButtonRight?: React.ReactNode;
  children: CarouselItem[] | CarouselItem;
  slidesPerView?: number | 'auto';
  slidesPerGroupAuto?: boolean;
  onScrollComplete?: () => void;
}

export const Carousel = ({
  slidesPerView = 'auto',
  slidesPerGroupAuto = true,
  onScrollComplete,
  children,
  classProps,
}: CarouselProps) => {
  const swiperElRef = useRef(null);
  const [sliderProgress, setSliderProgress] = useState<number>(0);
  const [maxSlides, setMaxSlides] = useState<number>(20);

  const defaultStyle = {
    root: 'w-full flex-none relative group-hover:pointer-events-auto overflow-x-clip',
    carousel: '!overflow-visible',
    slide: '!w-fit hover:z-20 pr-4 last:pr-0 group/swiper-slide',
    slidesWrapper: '',
    buttonContainer: 'absolute inset-0 flex justify-between items-center z-20 pointer-events-none',
    button:
      'size-12 pointer-events-auto bg-black hover:bg-gray-900 border-gray-650 rounded-full hover:opacity-100 opacity-60 grid place-items-center',
    buttonIcon: 'text-white',
  } as CarouselProps['classProps'];
  const styles = composeStyles(defaultStyle, classProps);

  function changeSlide(changeCount: number) {
    setMaxSlides(-1);
    if (changeCount === 1) {
      swiperElRef.current.swiper.slideNext();
    } else if (changeCount === -1) {
      swiperElRef.current.swiper.slidePrev();
    }
    setSliderProgress(swiperElRef.current?.swiper?.progress);
  }

  const visibleChildren = useMemo(
    () => (Array.isArray(children) ? (maxSlides > 0 ? children.slice(0, maxSlides) : children) : [children]),
    [children, maxSlides],
  );

  if (!visibleChildren || visibleChildren.length < 1) return null;

  return (
    <div className={styles.root} data-testid="carousel">
      <div className={styles.buttonContainer}>
        <div>
          {sliderProgress !== 0 && (
            <button
              className={twMerge(styles.button, styles.buttonLeft)}
              onClick={() => {
                changeSlide(-1);
              }}
            >
              <IconPrev classProps={{ root: styles.buttonIcon }} />
            </button>
          )}
        </div>
        <div>
          {sliderProgress !== 1 && (
            <button
              className={twMerge(styles.button, styles.buttonRight)}
              onClick={() => {
                changeSlide(1);
              }}
            >
              <IconNext classProps={{ root: styles.buttonIcon }} />
            </button>
          )}
        </div>
      </div>
      <Swiper
        modules={[Mousewheel]}
        slidesPerView={slidesPerView}
        slidesPerGroupAuto={slidesPerGroupAuto}
        onSlideChangeTransitionEnd={onScrollComplete}
        speed={500}
        loop={false}
        cssMode={false}
        mousewheel={{ enabled: true, forceToAxis: true, releaseOnEdges: true, thresholdDelta: 15 }}
        grabCursor={true}
        ref={swiperElRef}
        className={styles.carousel}
        wrapperClass={styles.slidesWrapper}
      >
        {visibleChildren.map((child, index) => (
          <SwiperSlide key={index} className={styles.slide}>
            {React.cloneElement(child, {
              classProps: composeStyles(child.props.classProps, {}),
            })}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
