import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export const IconPrev = ({ classProps }: PropsWithClassProps) => {
  const rootStyle = twMerge('w-6 h-3', classProps?.root);
  return (
    <svg className={rootStyle} width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9375 3.8125H1M1 3.8125L3.7169 6.625M1 3.8125L3.7169 1" stroke="currentColor" />
    </svg>
  );
};
