import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export const IconNext = ({ classProps }: PropsWithClassProps) => {
  const rootStyle = twMerge('w-6 h-3', classProps?.root);
  return (
    <svg className={rootStyle} width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.8125L13.9375 3.8125M13.9375 3.8125L11.2206 1M13.9375 3.8125L11.2206 6.625" stroke="currentColor" />
    </svg>
  );
};
