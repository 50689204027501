import { useEffect, useRef } from 'react';

export const useEventListener = (eventName, handler, element = null) => {
  const savedHandler = useRef(null);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      if (element?.addEventListener === undefined && window?.addEventListener === undefined) return;
      const localElement = element || window;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      localElement.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        localElement.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
};
