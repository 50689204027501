import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Image, Link } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { useEventListener } from '@lib/hooks/useEventListener';

export interface StreamingTeaserProps extends PropsWithClassProps<'main' | 'title' | 'image' | 'fallback'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: any;
  listicleType:
    | 'MOVIE'
    | 'SHOW'
    | 'CHANNEL'
    | 'SERIES'
    | 'EPISODE'
    | 'PERSON'
    | 'GENRE'
    | 'PROVIDER'
    | 'MANUAL'
    | 'COLLECTION_ENTRY';
  lastScroll?: number;
  showInitially?: boolean;
}

const linkBase = {
  MOVIE: '/filme/',
  SHOW: '/serien/',
  GENRE: '/genres/',
  EPISODE: '',
};

export const StreamingTeaser = ({
  entry,
  listicleType,
  lastScroll,
  showInitially,
  classProps,
}: StreamingTeaserProps) => {
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '50px 350px',
    triggerOnce: true,
  });

  const entryType =
    listicleType && listicleType !== 'COLLECTION_ENTRY' ? listicleType : entry.__typename?.toUpperCase();
  const [screenPosition, setScreenPosition] = useState('left');

  const teaserImage = entry?.backdrop_image || entry?.cover_image;
  const elementStyle = twMerge('relative w-72 grid group h-full min-h-56 md:min-h-60 hover:z-20', classProps?.root);
  const elementLinkBase = linkBase[entryType.toUpperCase()] || '';
  const domElement = useRef(null);

  const calculateScreenPosition = () => {
    if (domElement?.current && window) {
      const screenWidth = window.innerWidth;
      const elementBox = domElement.current.getBoundingClientRect();
      if (elementBox.left > screenWidth) return setScreenPosition('right');
      const leftPercentage = ((elementBox.left + elementBox.width / 2) / screenWidth) * 100;
      if (leftPercentage < 33) {
        setScreenPosition('left');
      } else if (leftPercentage > 66) {
        setScreenPosition('right');
      } else {
        setScreenPosition('center');
      }
    }
  };

  const itemVisible = useMemo(() => inView || showInitially, [inView, showInitially]);

  useEffect(() => {
    if (!domElement || !window) return;
    calculateScreenPosition();
  }, [lastScroll, itemVisible]);

  useEventListener('resize', calculateScreenPosition);

  const screenPositionStyle = {
    left: 'sm:group-hover:-right-10',
    center: 'sm:group-hover:-left-5 sm:group-hover:-right-5',
    right: 'sm:group-hover:-left-10',
  };

  const mainStyle = twMerge(
    'block absolute inset-0 group-hover:rounded-lg content-auto overflow-hidden bg-gray-800/0 -bottom-12 pb-0 transition-all duration-150 sm:group-hover:-top-20 sm:group-hover:-bottom-6 md:group-hover:-bottom-12 sm:group-hover:bg-gray-800/100 sm:group-hover:shadow-lg',
    screenPositionStyle[screenPosition || 'center'],
    classProps?.main,
  );

  return (
    <Link
      href={elementLinkBase + '' + (entry.slug || entry.id)}
      classProps={{ root: elementStyle }}
      key={entry.id}
      ref={inViewRef}
    >
      {itemVisible && (
        <div ref={domElement} className={mainStyle}>
          {teaserImage?.file?.path ? (
            <Image
              src={teaserImage.file.path}
              fill
              alt={entry.title}
              copyright={teaserImage?.path?.copyright}
              sizes="480px"
              loading="lazy"
              decoding="async"
              classProps={{
                root: 'object-cover',
                container: twMerge(
                  'w-full h-auto dark relative aspect-video overflow-hidden rounded-lg sm:group-hover:rounded-b-none',
                  classProps?.image,
                ),
              }}
            />
          ) : (
            <FallbackCard
              classProps={{
                root: twMerge(
                  'dark relative h-auto aspect-video w-full overflow-hidden rounded-lg sm:group-hover:rounded-b-none',
                  classProps?.fallback,
                ),
              }}
            />
          )}
          <div
            className={twMerge(
              'hyphenate mt-2 line-clamp-3 font-herokid text-sm font-medium text-gray-900 transition-spacings duration-150 group-hover:line-clamp-1 group-hover:text-white dark:text-white sm:group-hover:px-4 md:pt-5 md:text-base',
              classProps?.title,
            )}
          >
            {entry.title}
          </div>
          <div className="mt-2 grid h-0 w-full grid-rows-[1fr,auto] overflow-hidden px-4 sm:group-hover:h-28">
            <div className="mt-1 flex flex-wrap content-start items-start gap-1">
              {entry.__typename !== 'Entity' &&
                entry.genres?.slice(0, 5).map((genre, idx) => <Genre key={genre.id + idx} genre={genre} />)}
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm text-white">
              <span className="font-bold">{entry.year}</span>
              {entry.__typename !== 'Entity' && entry?.runtime > 0 && <span>•&nbsp;{entry.runtime}Min</span>}
              {entry.__typename === 'Movie' && entry?.age_rating && (
                <>
                  •&nbsp;<span className="border border-gray-600 p-1 leading-body">{entry.age_rating}</span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};
